import React, { useState, useEffect } from "react";

const testimonios = [
    {
        quote: "Excelente servicio al cliente y calidad. Muy recomendable.",
        author: "Guess México",
        role: "Visual Manager",
        img: "/assets/img/home-page/iconos-testimoniales/8.png", // Sustituir por la imagen real
    },
    {
        quote: "La calidad en materiales y el servicio, su sentido de urgencia y proactividad es lo que definen la experiencia con Moti.",
        author: "American Eagle",
        role: "Visual Manager",
        img: "/assets/img/home-page/iconos-testimoniales/10.png",
    },
    {
        quote: "¡Lo hacen posible! Todas nuestras campañas han superado las expectativas.",
        author: "Pandora México",
        role: "Visual Manager",
        img: "/assets/img/home-page/iconos-testimoniales/9.png",
    },
];

const TestimonialSlider = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex((prevIndex) => (prevIndex + 1) % testimonios.length);
        }, 10000); // Cambia de testimonio cada 5 segundos

        return () => clearInterval(interval);
    }, []);

    const handlePrevClick = () => {
        setActiveIndex((prevIndex) =>
            prevIndex === 0 ? testimonios.length - 1 : prevIndex - 1
        );
    };

    const handleNextClick = () => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % testimonios.length);
    };

    return (
        <div className="main-testimonial">
            <div>
                <div className="testimonial-title">
                    <span>FACTOR WOW</span>
                    <h2 className="moti-testimonial">
                        Así es trabajar con <span className="highlight">moti</span>
                    </h2>
                </div>
            </div>
            <div className="testimonial-slider">
                <button className="arrow left" onClick={handlePrevClick}>
                    &#9664;
                </button>
                {testimonios.map((testimonio, index) => (
                    <div
                        key={index}
                        className={`testimonial ${index === activeIndex ? "active" : "inactive"}`}
                    >
                        <img
                            src={`/assets/img/home-page/iconos-testimoniales/quote-icon-${index + 1}.png`}
                            alt={`quote icon ${index + 1}`}
                            className="quote-icon"
                        />
                        <p className="quote">"{testimonio.quote}"</p>
                        <div className="author-info">
                            <img src={testimonio.img} alt={testimonio.author} />
                            <div>
                                <p className="author">{testimonio.author}</p>
                                <p className="role">{testimonio.role}</p>
                            </div>
                        </div>
                    </div>
                ))}
                <button className="arrow right" onClick={handleNextClick}>
                    &#9654;
                </button>
                <div className="dots">
                    {testimonios.map((_, index) => (
                        <span
                            key={index}
                            className={`dot ${index === activeIndex ? "active" : ""}`}
                            onClick={() => setActiveIndex(index)}
                        ></span>
                    ))}
                </div>
            </div>
        </div>
    );
    
};

export default TestimonialSlider;
