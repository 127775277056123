import React from "react";


const Infobanner = () => {
    return (
        <div className="textbanner-container">
            <div className="textbanner-title">
                <span className="textbanner-title">Aviso de Privacidad</span>
            </div>
            <div className="textbanner-footer">
                <span className="textbanner-footer">MOTI DIGITAL S DE RL DE CV</span>
            </div>
            <div className="textbanner-text">
                <p className="privacy-p">De conformidad con lo establecido en el Artículo 17 de la Ley Federal de Protección de Datos Personales en Posesión de los
                    Particulares le informamos que MOTI DIGITAL S DE RL DE CV (en lo sucesivo la “Responsable”) y/o cualquiera de sus empleados,
                    (en lo sucesivo los “Colaboradores”), tratarán los datos personales que se recaben de usted con la finalidad La información
                    proporcionada, será utilizada única y exclusivamente para fines de contratación laboral durante su permanencia en la empresa
                    con quien contrata y seguridad para la misma de brindarle los diversos productos y servicios que ofrécela Responsable, así como
                    en su caso la entrega o prestación de dichos productos y servicios en su domicilio, pudiendo gestionar, administrar y dar de alta en
                    nuestro sistema la relación con usted para llevar acabo la facturación y entrega a domicilio según sea el caso.</p>
            </div>
            <div className="textbanner-footer">
                <span className="textbanner-footer">Responsable de la protección de sus datos personales</span>
            </div>
            <div className="textbanner-text">
                <p className="privacy-p">MOTI DIGITAL S DE RL DE CV y/o cualquiera de sus Colaboradores, le hacen de su conocimiento que la información
                    que proporciona cuando usted utiliza los Servicios, representa un elemento importante para nuestra operación y la prestación
                    de los mismos Servicios, por lo que será manejada como confidencial y la protegeremos a través de procesos internos,
                    buscando siempre su mayor protección y resguardo. MOTI DIGITAL S DE RL DE CV, es responsable del tratamiento de los
                    datos personales que le sean recabados de acuerdo a este Aviso de Privacidad, señalando como domicilio para estos fines el
                    ubicado en Mariano Azuela 7 col. Ladrón de Guevara, Guadalajara, Jalisco, C.P. 44600 (en lo sucesivo la “Responsable”).</p>
            </div>
            <div className="textbanner-footer">
                <span className="textbanner-footer">Responsable de la protección de sus datos personales</span>
            </div>
            <div className="textbanner-text">
                <p className="privacy-p">MOTI DIGITAL S DE RL DE CV y/o cualquiera de sus Colaboradores, le hacen de su conocimiento que la información
                    que proporciona cuando usted utiliza los Servicios, representa un elemento importante para nuestra operación y la prestación
                    de los mismos Servicios, por lo que será manejada como confidencial y la protegeremos a través de procesos internos,
                    buscando siempre su mayor protección y resguardo. MOTI DIGITAL S DE RL DE CV, es responsable del tratamiento de los
                    datos personales que le sean recabados de acuerdo a este Aviso de Privacidad, señalando como domicilio para estos fines el
                    ubicado en Mariano Azuela 7 col. Ladrón de Guevara, Guadalajara, Jalisco, C.P. 44600 (en lo sucesivo la “Responsable”).</p>
            </div>
        </div>
    );
};


export default Infobanner;
