import React from "react";

const Gif= () => {
return (

    <div className="main-gif-container">
        <div className="gif-wrapper">
            <img src="/assets/img/gif/EtiquetasMD.gif" alt="" />
            <img src="/assets/img/gif/EtiquetasMDII.gif" alt="" />
        </div>
    </div>

);

}

export default Gif;