import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const SimpleSlider = () => {
  const slides = [
    {
      img: 'assets/img/visual-merch/01 POSTER.png',
      title: 'Slide 1',
      description: 'Descripción del Slide 1',
    },
    {
      img: 'assets/img/visual-merch/02 LIBROS.png',
      title: 'Slide 2',
      description: 'Descripción del Slide 2',
    },
    {
      img: 'assets/img/visual-merch/03 MENÚ.png',
      title: 'Slide 3',
      description: 'Descripción del Slide 3',
    },
    {
      img: 'assets/img/visual-merch/04 BROCHURE.png',
      title: 'Slide 4',
      description: 'Descripción del Slide 4',
    },
    {
      img: 'assets/img/visual-merch/05 FOLLETO.png',
      title: 'Slide 4',
      description: 'Descripción del Slide 4',
    },
    {
      img: 'assets/img/visual-merch/06 TRÍPTICO.png',
      title: 'Slide 1',
      description: 'Descripción del Slide 1',
    },
    {
      img: 'assets/img/visual-merch/07 PHOTOBOOKS.png',
      title: 'Slide 2',
      description: 'Descripción del Slide 2',
    },
    {
      img: 'assets/img/visual-merch/08 PAPELERÍA.png',
      title: 'Slide 3',
      description: 'Descripción del Slide 3',
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
  };

  return (

    <div>
      <div className="textbanner-container">
        <div class="textbanner-title"><span class="head_style_2">impresión institucional</span></div>
        <div class="textbanner-text"><span class="textbanner-text">Un tinte de perfección</span></div>
      </div>
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <div key={index}>
            <img
              src={slide.img}
              alt={slide.title}
              style={{ width: '100%', height: 'auto' }}
            />
          </div>
        ))}
      </Slider>
      <div class="title_section"><span class="techtext-title">Tu marca, lo vale</span></div>
      <div className="div-back">
        <a href="/">
          <img src="./assets/img/regresar.png" alt="boton-regresar" className="img-back" />
        </a>
      </div>
    </div>
  );
};

export default SimpleSlider;
