import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const SimpleSlider = () => {
  const slides = [
    {
      img: 'assets/img/visual/00 POP.png',
      title: 'Slide 1',
      description: 'Descripción del Slide 1',
      link: '/#/pop',
    },
    {
      img: 'assets/img/visual/00 PUBLICIDAD EXTERIOR.png',
      title: 'Slide 2',
      description: 'Descripción del Slide 2',
      link: '/#/exterior',
    },
  ];

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <div>
      <div className="textbanner-container">
        <div className="textbanner-title">
          <span className="head_style_2">visual merchandising</span>
        </div>
        <div className="textbanner-text">
          <span className="textbanner-text">Expresionismo visual en su máxima revelación</span>
        </div>
      </div>
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <div key={index}>
            <a href={slide.link} rel="noopener noreferrer">
              <img
                src={slide.img}
                alt={slide.title}
                style={{ width: '100%', height: 'auto' }}
              />
            </a>
          </div>
        ))}
      </Slider>
      <div className="title_section">
        <span className="techtext-title">Tu marca a los ojos del mundo</span>
      </div>
      <div className="div-back">
        <a href="/">
          <img src="./assets/img/regresar.png" alt="boton-regresar" className="img-back" />
        </a>
      </div>
    </div>
  );
};

export default SimpleSlider;
