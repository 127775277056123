import React from 'react';

const ProyectosRecientes = () => {
    const proyectos = [
        {
            nombre: 'Pandora',
            descripcion: 'Descubre nuestra última campaña para las boutiques de Pandora.',
            imgSrc: '/assets/img/home-page/recent-projects/pandora.jpg', 
            link: '#',
            color: '#3ecac1',
        },
        {
            nombre: 'Birdman',
            descripcion: 'Conoce las cajas premium que realizamos para Birdman.',
            imgSrc: '/assets/img/home-page/recent-projects/birdman.jpg',  
            link: '#',
            color: '#511029',
        },
        {
            nombre: 'Aniversario Moti',
            descripcion: '¡Revive nuestro 20 aniversario!',
            imgSrc: '/assets/img/home-page/recent-projects/aniversario.jpg',  
            link: '#',
            color: '#f20d18',
        },
    ];

    return (
        <div className="proyectos-container">
            <div className="title-wraper-proyectos">

            <span className='soft-title-proyectos'>lo hacemos posible</span>
            <h2 className="proyectos-title">Proyectos recientes <span class="proyecto-highlight ">moti</span></h2>
            </div>
            <div className="proyectos-list">
                {proyectos.map((proyecto, index) => (

                    <div className='main-recent'>
                        <div className="proyecto-card" key={index} style={{ borderColor: proyecto.color }}>
                            <img src={proyecto.imgSrc} alt={proyecto.nombre} className="proyecto-image" />

                        </div>
                        <div className="text">
                            <div className="proyecto-content">
                                <h3>{proyecto.nombre}</h3>

                            </div>
                            <p>{proyecto.descripcion}</p>
                            <div className='div-button-projects'>
                                <img src="../assets/img/home-page/ecommerce/dots-moti.png" alt="dots" class="dots-projects" />
                                <a href={proyecto.link} className="learn-more">
                                    <span>Learn More</span>
                                </a>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ProyectosRecientes;
