import React from 'react';

const EspecialProjects = () => {
    const Projects = [
        {
            videoSrc: 'assets/videos/especial/video DREAMFIELDS.mp4',
            tittle: 'Escenarios',
            description: 'La experiencia de marca no se construye, se percibe'
        },
        {
            videoSrc: 'assets/videos/especial/video intro nike.mp4',
            tittle: 'Escaparates',
            description: 'Visibilidad para tu producto en punto de venta'
        },
        {
            videoSrc: 'assets/videos/especial/Video Shell.mp4',
            tittle: 'Showroom',
            description: 'Estrategias de marketing de alto valor'
        },
        {
            videoSrc: 'assets/videos/especial/Video Vidanta.mp4',
            tittle: 'Sala de ventas',
            description: 'Impacto de gran calidad en cualquier superficie'
        }
    ];

    return (
        <section className="section dark_section digital_design" id="Tech">
            <div className="animation_wrap">
                <div className="title_section reveal active">

                    <h3 className="head_style_2">Brand Experience</h3>
                </div>
            </div>
            <div className="projects-container">
                {Projects.map((project, index) => (
                    <div className="project-video">
                        <div className="reveal active">
                            <h3 className="projects-tittle">{project.tittle}</h3>
                        </div>
                        <video width="90%" autoPlay muted loop className='reveal active' loading="lazy" preload="auto">
                            <source src={project.videoSrc} type="video/mp4" />
                            Your web browser doesn't support <code>video</code> element.
                            <a href="https://www.google.com/chrome/browser/desktop/index.html" target="_blank">
                                Install Google Chrome
                            </a>
                        </video>
                        <div className="reveal active poject-description">
                            <h3 >{project.description}</h3>
                        </div>
                    </div>
                ))}
            </div>
            <div className="container project-video">
                <div className="title_section reveal active">
                    <h3 className="head_style_2">NUESTRA AVENTURA CREATIVA, EL MEJOR ALIADO DE TU MARCA</h3>
                    <h4 className="creation-subtitle">Transformamos tu marca desde la conceptualización<br></br> hasta la impliementación de tu proyecto</h4>
                </div>
                <video className="video-moti-way" width="100%" autoPlay muted loop loading="lazy" preload="auto">
                    <source src="assets/videos/especial/Manera moti animated.mp4" type="video/mp4" />
                    Your web browser doesn't support <code>video</code> element.
                    <a href="https://www.google.com/chrome/browser/desktop/index.html" target="_blank">
                        Install Google Chrome
                    </a>
                </video>
            </div>
            <div className="div-back">
                <a href="/">
                    <img src="./assets/img/regresar.png" alt="boton-regresar" className="img-back" />
                </a>
            </div>
        </section>
    );
};

export default EspecialProjects;
