import React, { useState, useEffect, useMemo } from "react";

const Navbar = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [isSticky, setIsSticky] = useState(false);

    const links = [
        { link: "/#inicio", title: "Inicio" },
        { link: "/#products", title: "Productos y servicios" },
        { link: "/#Tech", title: "Tecnologías" },
        { link: "https://motistore.com", title: "Tienda en línea", target: "_blank" },
        { link: "/#Us", title: "Nosotros" },
        { link: "/#Footer", title: "Contacto" },
    ];

    const RenderLogo = React.memo(() => (
        <div className="logo-container">
            <a href="/" className="logo">
                <img src="./assets/img/LogoMoti.png" alt="Logo Moti" loading="lazy" />
            </a>
        </div>
    ));

    const toggleMenu = () => {
        setMenuOpen((prev) => !prev);
    };

    const handleScroll = () => {
        const stickyPoint = 433;
        setIsSticky(window.scrollY > stickyPoint);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const navbarStyle = useMemo(() => ({
        paddingTop: isSticky ? '0.3rem' : '0',
    }), [isSticky]);

    const innerNavbarStyle = useMemo(() => ({
        borderRadius: isSticky ? '3rem' : '0',
        transition: '0.7s ease-in-out',
        marginLeft: isSticky ? '1rem' : '0',
        marginRight: isSticky ? '1rem' : '0',
    }), [isSticky]);

    return (
        <div className="navbar-container" style={navbarStyle}>
            <div className="navbar" style={innerNavbarStyle}>
                <RenderLogo />
                <div className="link-wrapper">
                    {links.map((element, index) => (
                        <a className="navbar-item" href={element.link} target={element.target} key={index}>
                            {element.title}
                        </a>
                    ))}
                </div>
            </div>

            {menuOpen && (
                <div className="mobile-menu">
                    <div className="mobile-menu-overlay" onClick={toggleMenu}></div>
                    <div className="mobile-menu-links">
                        {links.map((element, index) => (
                            <a
                                className="mobile-menu-item"
                                href={element.link}
                                target={element.target}
                                key={index}
                                onClick={toggleMenu}
                            >
                                {element.title}
                            </a>
                        ))}
                    </div>
                </div>
            )}

            <div className="mobile-menu-button" onClick={toggleMenu}>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
            </div>
        </div>
    );
};

export default Navbar;
