import React from 'react';

const TechSection = () => {
  const technologies = [
    {
      videoSrc: 'assets/videos/video_tecnologias/Tecnologias UV.mp4',
      mobileVideoSrc: 'assets/videos/video_tecnologias/Tecnologias UV-mobile.webm',
    },
    {
      videoSrc: 'assets/videos/video_tecnologias/Tecnologias LATEX.mp4',
      mobileVideoSrc: 'assets/videos/video_tecnologias/Tecnologias LATEX-mobile.webm',
    },
    {
      videoSrc: 'assets/videos/video_tecnologias/Tecnologias SUBLIMACION DURST.mp4',
      mobileVideoSrc: 'assets/videos/video_tecnologias/Tecnologias SUBLIMACION DURST-mobile.webm',
    },
    {
      videoSrc: 'assets/videos/video_tecnologias/Tecnologias INDIGO.mp4',
      mobileVideoSrc: 'assets/videos/video_tecnologias/Tecnologias INDIGO-mobile.webm',
    },
    {
      videoSrc: 'assets/videos/video_tecnologias/Tecnologias ETIQUETAS.mp4',
      mobileVideoSrc: 'assets/videos/video_tecnologias/Tecnologias ETIQUETAS-mobile.webm',
    },
    {
      videoSrc: 'assets/videos/video_tecnologias/Tecnologias FOTOGRAFIA.mp4',
      mobileVideoSrc: 'assets/videos/video_tecnologias/Tecnologias FOTOGRAFIA-mobile.webm',
    },
  ];

  return (
    <section className="section dark_section digital_design" id="Tech">
      {technologies.map((tech, index) => (
        <div className="video-tech" key={index}>
          <div className="video-box">
            {/* Video de escritorio */}
            <video
              className="video-desktop"
              loop
              autoPlay
              muted
              playsInline
              preload="auto"
              loading="lazy" 
              width="100%"
              height="auto"
            >
              <source src={tech.videoSrc} type="video/mp4" />
              Tu navegador no soporta el elemento <code>video</code>.
              <a href="https://www.google.com/chrome/browser/desktop/index.html" target="_blank">
                Instalar Google Chrome
              </a>
            </video>

            {/* Video móvil */}
            <video
              className="video-mobile"
              loop
              autoPlay
              muted
              playsInline
              preload="auto"
              loading="lazy" 
              width="100%"
              height="auto"
            >
              <source src={tech.mobileVideoSrc} type="video/webm" />
              Tu navegador no soporta el elemento <code>video</code>.
              <a href="https://www.google.com/chrome/browser/desktop/index.html" target="_blank">
                Instalar Google Chrome
              </a>
            </video>
          </div>
        </div>
      ))}
    </section>
  );
};

export default TechSection;
