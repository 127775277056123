import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const SimpleSlider = () => {
    const slides = [
        {
            img: 'assets/img/labels/01 BOPP METALIZADO.png',
            title: 'Slide 1',
            description: 'Descripción del Slide 1',
        },
        {
            img: 'assets/img/labels/01 BOPP TRANSPARENTE.png',
            title: 'Slide 2',
            description: 'Descripción del Slide 2',
        },
        {
            img: 'assets/img/labels/03 BOPP BLANCO.png',
            title: 'Slide 3',
            description: 'Descripción del Slide 3',
        },
        {
            img: 'assets/img/labels/04 SEMIGLOSS.png',
            title: 'Slide 4',
            description: 'Descripción del Slide 4',
        },
        {
            img: 'assets/img/labels/05 ESTATE LABEL 4.png',
            title: 'Slide 4',
            description: 'Descripción del Slide 4',
        },
        {
            img: 'assets/img/labels/06 COTTON.png',
            title: 'Slide 1',
            description: 'Descripción del Slide 1',
        },
        {
            img: 'assets/img/labels/07 EGGSHELL.png',
            title: 'Slide 2',
            description: 'Descripción del Slide 2',
        },
    ];

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
    };

    return (

        <div>
            <div className="textbanner-container">
                <div class="textbanner-title"><span class="head_style_2">ETIQUETAS Y MANGAS</span></div>
                <div class="textbanner-text"><span class="textbanner-text">Envuelve tus productos en calidad premium</span></div>
            </div>
            <Slider {...settings}>
                {slides.map((slide, index) => (
                    <div key={index}>
                        <img
                            src={slide.img}
                            alt={slide.title}
                            style={{ width: '100%', height: 'auto' }}
                        />
                    </div>
                ))}
            </Slider>
            <div class="title_section"><span class="techtext-title">Percepción, es realidad</span></div>
            <div className="div-back">
                <a href="/">
                    <img src="./assets/img/regresar.png" alt="boton-regresar" className="img-back" />
                </a>
            </div>        
        </div>
    );
};

export default SimpleSlider;
