import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const SimpleSlider = () => {
  const slides = [
    {
      img: 'assets/img/visual/pop/01 STAND.png',
      title: 'Slide 1',
      description: 'Descripción del Slide 1',
    },
    {
      img: 'assets/img/visual/pop/02 ISLA.png',
      title: 'Slide 1',
      description: 'Descripción del Slide 1',
    },
    {
      img: 'assets/img/visual/pop/03 GLORIFICADOR.png',
      title: 'Slide 1',
      description: 'Descripción del Slide 1',
    },
    {
      img: 'assets/img/visual/pop/04 EXHIBIDOR.png',
      title: 'Slide 1',
      description: 'Descripción del Slide 1',
    },
    {
      img: 'assets/img/visual/pop/05 CAJA DE LUZ.png',
      title: 'Slide 1',
      description: 'Descripción del Slide 1',
    },
    {
      img: 'assets/img/visual/pop/06 VINIL.png',
      title: 'Slide 1',
      description: 'Descripción del Slide 1',
    },
    {
      img: 'assets/img/visual/pop/07 DISPLAY.png',
      title: 'Slide 1',
      description: 'Descripción del Slide 1',
    },
    {
      img: 'assets/img/visual/pop/08 CENEFA.png',
      title: 'Slide 1',
      description: 'Descripción del Slide 1',
    },
    {
      img: 'assets/img/visual/pop/09 COPETES.png',
      title: 'Slide 1',
      description: 'Descripción del Slide 1',
    },
    {
      img: 'assets/img/visual/pop/10 PENDONES.png',
      title: 'Slide 1',
      description: 'Descripción del Slide 1',
    },
    {
      img: 'assets/img/visual/pop/11 PRECIADORES.png',
      title: 'Slide 1',
      description: 'Descripción del Slide 1',
    },
    {
      img: 'assets/img/visual/pop/12 BANNER.png',
      title: 'Slide 1',
      description: 'Descripción del Slide 1',
    },
    {
      img: 'assets/img/visual/pop/13 ROLL UP.png',
      title: 'Slide 1',
      description: 'Descripción del Slide 1',
    },
    {
      img: 'assets/img/visual/pop/14 STOPPER.png',
      title: 'Slide 1',
      description: 'Descripción del Slide 1',
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
  };

  return (

    <div>
      <div className="textbanner-container">
        <div class="textbanner-title"><span class="head_style_2">publicidad exterior</span></div>
        <div class="textbanner-text"><span class="textbanner-text">Tu marca en todas partes</span></div>
      </div>
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <div key={index}>
            <img
              src={slide.img}
              alt={slide.title}
              style={{ width: '100%', height: 'auto' }}
            />
          </div>
        ))}
      </Slider>
      <div class="title_section"><span class="techtext-title">Posibilidades infinitas</span></div>
      <div className="div-back">
        <a href="/visual">
          <img src="./assets/img/regresar.png" alt="boton-regresar" className="img-back" />
        </a>
      </div>
    </div>
  );
};

export default SimpleSlider;
